import {
    ALL_TEAM_USERS_DATA,
    ADD_USER,
    DELETE_USER,
    ALL_ROLES,
    ALL_USERS_DATA,
    ALL_ACTIVE_USERS_DATA,
    ALL_DEACTIVE_USERS_DATA,
    RESEND_EMAIL,
    RESEND_EMAIL_SUCCESS,
    RESEND_EMAIL_ERROR
} from '../actions';

const INIT_STATE = {
    teamList: [],
    userRoles: [],
    activeUserList: [],
    deactiveUserList: [],
    loading: false,
    error: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ALL_TEAM_USERS_DATA: {
            return {
                ...state,
                teamList: action.payload,
            };
        }
        case ALL_USERS_DATA: {
            return {
                ...state,
                teamList: action.payload,
            };
        }
        case ALL_ACTIVE_USERS_DATA: {
            return {
                ...state,
                activeUserList: action.payload,
            };
        }
        case ALL_DEACTIVE_USERS_DATA: {
            return {
                ...state,
                deactiveUserList: action.payload,
            };
        }
        case ADD_USER: {
            return {
                ...state,
                teamList: [...state.teamList, action.payload],
            };
        }
        case DELETE_USER: {
            const teamList = [...state.teamList];
            const manipulatedData = teamList.filter((user) => user.uuid !== action.payload);
            return {
                ...state,
                teamList: manipulatedData,
            };
        }
        case ALL_ROLES: {
            return {
                ...state,
                userRoles: action.payload,
            };
        }
        case RESEND_EMAIL: {
            return { ...state, loading: true };
        }
        case RESEND_EMAIL_SUCCESS: {
            return { ...state, loading: false };
        }
        case RESEND_EMAIL_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }
        default:
            return state;
    }
};
