/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_TYPE = 'LOGIN_USER_TYPE';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const SIGNUP_BUTTON_ACTIVE = 'SIGNUP_BUTTON_ACTIVE';
export const SIGNUP_BUTTON_DEACTIVE = 'SIGNUP_BUTTON_DEACTIVE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const FORGOT_PASSWORD_CLEAR_ERROR = 'FORGOT_PASSWORD_CLEAR_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CREATE_PASSWORD = 'CREATE_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const CREATE_PASSWORD_SUCCESS = 'CREATE_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const CREATE_PASSWORD_ERROR = 'CREATE_PASSWORD_ERROR';
export const RESENT_BUTTON_DISABLE = 'RESENT_BUTTON_DISABLE';
export const RESET_TOKEN_VERIFICATION = 'RESET_TOKEN_VERIFICATION';
export const CREATE_TOKEN_VERIFICATION = 'CREATE_TOKEN_VERIFICATION';
export const RESET_TOKEN_VERIFICATION_SUCCESS = 'RESET_TOKEN_VERIFICATION_SUCCESS';
export const CREATE_TOKEN_VERIFICATION_SUCCESS = 'CREATE_TOKEN_VERIFICATION_SUCCESS';
export const RESET_TOKEN_VERIFICATION_ERROR = 'RESET_TOKEN_VERIFICATION_ERROR';
export const CREATE_TOKEN_VERIFICATION_ERROR = 'CREATE_TOKEN_VERIFICATION_ERROR';
export const RESET_TOKEN_VERIFICATION_ERROR_CLEAR = 'RESET_TOKEN_VERIFICATION_ERROR_CLEAR';
export const CREATE_TOKEN_VERIFICATION_ERROR_CLEAR = 'CREATE_TOKEN_VERIFICATION_ERROR_CLEAR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = 'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

export const FETCH_START = 'FETCH_START';
export const FETCH_ERROR = 'FETCH_ERROR';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';

/* Team Management Constants */
export const ALL_TEAM_USERS_DATA = 'ALL_TEAM_USERS_DATA';
export const ALL_USERS_DATA = 'ALL_USERS_DATA';
export const ALL_ACTIVE_USERS_DATA = 'ALL_ACTIVE_USERS_DATA';
export const ALL_DEACTIVE_USERS_DATA = 'ALL_DEACTIVE_USERS_DATA';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const ALL_ROLES = 'ALL_ROLES';
export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';

// Scrennshot
export const USER_SCREENSHOT = 'USER_SCREENSHOT';
export const USER_LIST_SCREENSHOT = 'USER_LIST_SCREENSHOT';
export * from './users/actions/TeamManagement';

// Browser History
export const HISTORY_LIST = 'HISTORY LIST';

// Department Management Constants
export const DEPATRTMENT_LIST = 'DEPATRTMENT_LIST';
export const DEPATRTMENT_DELETE = 'DEPATRTMENT_DELETE';

// Attendance Report Screen
export const ALL_ATTENDANCE_LIST = 'ALL_ATTENDANCE_LIST';
export const ATTENDANCE_PAGINATION = 'ATTENDANCE_PAGINATION';

// Company settings screen
export const ADMIN_PROFILE = 'ADMIN_PROFILE';
export const UPDATE_ADMIN_DETAILS = 'UPDATE_ADMIN_DETAILS';
export const COMPANY_DETAILS = 'COMPANY_DETAILS';
export const UPDATE_COMPANY_DETAILS = 'UPDATE_COMPANY_DETAILS';

// Company List Screen
export const COMPANY_LIST = 'COMPANY_LIST';
export const COMPANY_DETAIL = 'COMPANY_DETAIL';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const ADD_COMPANY = 'ADD_COMPANY';
export const ADD_PAYMENT = 'ADD_PAYMENT';
export const PAYMENT_LIST = 'PAYMENT_LIST';
export const RESEND_EMAIL = 'RESEND_EMAIL'
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_ERROR ='RESEND_EMAIL_ERROR';

// Deleted Company Screen
export const DELETED_COMPANY = 'DELETED_COMPANY';
export const REVIVE_COMPANY = 'REVIVE_COMPANY';
export const REVIVE_DELETED_MONTH_COMPANY = 'REVIVE_DELETED_MONTH_COMPANY';
export const ACTIVE_COMPANY = 'ACTIVE_COMPANY';
export const DELETE_ACTIVE_COMPANIES = 'DELETE_ACTIVE_COMPANIES';
export const DELETE_ADDED_MONTH_COMPANY = 'DELETE_ADDED_MONTH_COMPANY';
export const ADDED_IN_MONTH = 'ADDED_IN_MONTH';
export const DELETED_IN_MONTH = 'DELETED_IN_MONTH';

// Dashboard Super Admin
export const DASHBOARD_DATA = 'DASHBOARD_DATA';

// Deleted User Screen
export const DELETED_USER = 'DELETED_USER';
export const REVIVE_USER = 'REVIVE_USER';

// Manual Request Time
export const MANUAL_REQUEST_LIST = 'MANUAL_REQUEST_LIST';
export const MANUALREQUEST_PAGINATION = 'MANUALREQUEST_PAGINATION';

// Activity Reports Page
export const ACTIVITY_REPORTS_DATA = 'ACTIVITY_REPORTS_DATA';
export const USER_ACTIVITY_LIST = 'USER_ACTIVITY_LIST';
export const ALL_USER_ACTIVITY_STATS_DATA = 'ALL_USER_ACTIVITY_STATS_DATA';

// DashboardManagerandEmployee Screen
export const DASHBOARD_DATA_MANAGER_EMPLOYEE = 'DASHBOARD_DATA_MANAGER_EMPLOYEE';
