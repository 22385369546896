import { toast } from 'react-toastify';
import {
    FETCH_START,
    // LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    // REGISTER_USER,
    REGISTER_USER_SUCCESS,
    LOGIN_USER_ERROR,
    REGISTER_USER_ERROR,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    FORGOT_PASSWORD_CLEAR_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    RESET_TOKEN_VERIFICATION,
    RESET_TOKEN_VERIFICATION_SUCCESS,
    LOGIN_USER_TYPE,
    RESET_TOKEN_VERIFICATION_ERROR,
    CREATE_PASSWORD,
    CREATE_PASSWORD_SUCCESS,
    CREATE_TOKEN_VERIFICATION,
    CREATE_TOKEN_VERIFICATION_SUCCESS,
    CREATE_TOKEN_VERIFICATION_ERROR,
} from '../actions';
import axios from '../../utils/Api';
import { errorHandler } from '../../helpers/ErrorHandler';

export const signIn = (user, history) => async (dispatch) => {
    // dispatch({ type: LOGIN_USER, payload: user });
    dispatch({ type: FETCH_START });
    axios
        .post('/login', user)
        .then(async (response) => {
            if (response.data) {
                localStorage.setItem('token', response.data.access_token);
                localStorage.setItem('userData', JSON.stringify(response.data.data));
                dispatch({ type: LOGIN_USER_SUCCESS, payload: response.data });
                dispatch({
                    type: LOGIN_USER_TYPE,
                    payload: response.data.data.role.name,
                });
                if (history) {
                    history.push('/');
                }
                // dispatch({ type: FETCH_SUCCESS });
            } else {
                dispatch({ type: LOGIN_USER_ERROR, payload: response.data.error });
                toast.info('Login unsuccessfull', response.data.error);
            }
        })
        .catch((error) => {
            errorHandler(error, dispatch, history, toast);
        });
};
export const setTokens = () => async (dispatch) => {
    const token = localStorage.getItem('token');
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join(''),
    );
    const data = JSON.parse(jsonPayload);
    dispatch({ type: LOGIN_USER_TYPE, payload: data.data.RoleName });
    //   dispatch({ type: FETCH_SUCCESS });
};

export const registerUser = (user, history) => async (dispatch) => {
    // dispatch({ type: REGISTER_USER, payload: user });
    dispatch({ type: FETCH_START });
    axios
        .post('/register', user)
        .then(async (response) => {
            if (response.data) {
                dispatch({ type: REGISTER_USER_SUCCESS, payload: response.data.data });
                //   dispatch({ type: FETCH_SUCCESS });
                toast.info('Company registered successfully');
                if (history) {
                    history.push('/user/login');
                }
            } else {
                toast.error(response.data.message);
                dispatch({ type: REGISTER_USER_ERROR, payload: response.data.message });
            }
        })
        .catch((error) => {
            dispatch({ type: REGISTER_USER_ERROR, payload: error.response.data.Message });
            errorHandler(error, dispatch, history, toast);
        });
};

export const ChangeUserPassword = (userData, history) => async (dispatch) => {
    axios.defaults.headers.common.Authorization = localStorage.getItem('token');
    dispatch({ type: FETCH_START });
    axios
        .put('/changepassword', userData)
        .then(async (response) => {
            if (response.data) {
                dispatch({ type: RESET_PASSWORD_SUCCESS, payload: response.data });
                //   dispatch({ type: FETCH_SUCCESS });
                toast.success('Password changed successfully');
            } else {
                dispatch({ type: REGISTER_USER_ERROR, payload: response.data.error });
            }
        })
        .catch((error) => {
            errorHandler(error, dispatch, history);
            toast.warn('Current password do not match', error);
        });
};

export const logoutUser = (history) => async (dispatch) => {
    dispatch({ type: LOGOUT_USER });
    localStorage.clear();
    if (history) {
        history.push('/user/login');
    }
};

export const forgotPassword = (forgotUserMail, history) => async (dispatch) => {
    // forgotUserMail.path = window.location.origin + "/user/reset-password";
    forgotUserMail.path = '/user/reset-password';
    dispatch({ type: FORGOT_PASSWORD });
    axios
        .post('/forgotpassword', forgotUserMail)
        .then(async (response) => {
            if (response.data) {
                dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: forgotUserMail });
                toast.info('Mail sent successfully');
            } else {
                toast.error(response.data.errors);
                dispatch({
                    type: FORGOT_PASSWORD_ERROR,
                    payload: response.data.errors,
                });
            }
        })
        .catch((error) => {
            errorHandler(error, dispatch, history, toast);
        });
};

export const clearForgotPasswordError = () => async (dispatch) => {
    dispatch({ type: FORGOT_PASSWORD_CLEAR_ERROR });
};

export const tokenVerification = (token, history) => async (dispatch) => {
    dispatch({ type: RESET_TOKEN_VERIFICATION });
    axios
        .post('/tokenverification', { token })
        .then(async (response) => {
            if (response.data.Message) {
                dispatch({
                    type: RESET_TOKEN_VERIFICATION_SUCCESS,
                    payload: response.data,
                });
            }
        })
        .catch((error) => {
            if (error.response && error.response.status === 400) {
                dispatch({ type: RESET_TOKEN_VERIFICATION_ERROR, payload: error.response.data });
            } else {
                errorHandler(error, dispatch, history, toast);
            }
        });
};
export const createPasswordTokenVerification = (token, history) => async (dispatch) => {
    dispatch({ type: CREATE_TOKEN_VERIFICATION });
    axios
        .post('/tokenverification', { token })
        .then(async (response) => {
            if (response.data.Message) {
                dispatch({
                    type: CREATE_TOKEN_VERIFICATION_SUCCESS,
                    payload: response.data,
                });
            }
        })
        .catch((error) => {
            if (error.response && error.response.status === 400) {
                dispatch({ type: CREATE_TOKEN_VERIFICATION_ERROR, payload: error.response.data });
            } else {
                errorHandler(error, dispatch, history, toast);
            }
        });
};

export const resetPassword = (data, history) => async (dispatch) => {
    dispatch({ type: RESET_PASSWORD });
    axios
        .put('/resetpassword', data)
        .then(async (response) => {
            if (response) {
                toast.info('Password changed successfully');
                dispatch({ type: RESET_PASSWORD_SUCCESS });
                dispatch({ type: LOGOUT_USER });
                localStorage.removeItem('token');
                if (history) {
                    history.push('/user/login');
                }
            }
        })
        .catch((error) => {
            errorHandler(error, dispatch, history, toast);
        });
};
export const createPassword = (data, history) => async (dispatch) => {
    dispatch({ type: CREATE_PASSWORD });
    axios
        .put('/resetpassword?verify=true', data)
        .then(async (response) => {
            if (response) {
                toast.info('Password changed successfully');
                dispatch({ type: CREATE_PASSWORD_SUCCESS });
                dispatch({ type: LOGOUT_USER });
                localStorage.removeItem('token');
                if (history) {
                    history.push('/user/login');
                }
            }
        })
        .catch((error) => {
            errorHandler(error, dispatch, history, toast);
        });
};

export const resetPasswordSuccess = (newPassword) => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: newPassword,
});

export const resetPasswordError = (message) => ({
    type: RESET_PASSWORD_ERROR,
    payload: { message },
});
