import {
    COMPANY_LIST,
    COMPANY_DETAIL,
    DELETE_COMPANY,
    ADD_COMPANY,
    UPDATE_COMPANY_DETAILS,
    PAYMENT_LIST,
    ADD_PAYMENT,
    RESEND_EMAIL,
    RESEND_EMAIL_SUCCESS,
    RESEND_EMAIL_ERROR
} from '../actions';

const INIT_STATE = {
    companyList: [],
    companyDetail: {},
    companyTimingDetails: {},
    updatePayment: {},
    paymentList: {},
    loading: false,
    error: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case COMPANY_LIST: {
            return {
                ...state,
                companyList: action.payload,
            };
        }
        case ADD_COMPANY: {
            return {
                ...state,
                companyList: [...state.companyList, action.payload],
            };
        }
        case COMPANY_DETAIL: {
            return {
                ...state,
                companyDetail: action.payload,
            };
        }
        case DELETE_COMPANY: {
            const companyList = [...state.companyList];
            const manipulatedData = companyList.filter(
                (company) => company.companyId !== action.payload,
            );
            return {
                ...state,
                teamList: manipulatedData,
            };
        }

        case UPDATE_COMPANY_DETAILS: {
            return {
                ...state,
                companyTimingDetails: action.payload,
            };
        }

        case PAYMENT_LIST: {
            return {
                ...state,
                paymentList: action.payload,
            };
        }

        case ADD_PAYMENT: {
            return {
                ...state,
                paymentList: [...state.paymentList, action.payload],
            };
        }

        case RESEND_EMAIL: {
            return { ...state, loading: true };
        }
        case RESEND_EMAIL_SUCCESS: {
            return { ...state, loading: false };
        }
        case RESEND_EMAIL_ERROR: {
            return { ...state, loading: false, error: action.payload };
        }

        default:
            return state;
    }
};
